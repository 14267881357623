.App {
  text-align: center;
}

html, body, iframe { 
  height: 100%; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-pdf__Page__canvas {
  margin: 15px auto;
  border: 2px solid black;
}

#pdfDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.pdfElement {
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

a { 
  color: inherit; 
} 

a:hover { 
  color: inherit; 
  text-decoration: none;
} 

#myLogo {
  max-width: 9em;
  max-height: 4em;
}

#downloadButton {
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-size: 1.5em;
  position: absolute;
  z-index: 0;
  margin:0;
  padding:0;
}

.footer {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(233, 219, 175);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

#mapLink {
  color: grey;
  margin: .5em;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formDiv {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
}

form {
  width: 60%;
}

label {
  width: 100%;
}

.alert-box {
  color:#555;
  border-radius:10px;
  font-family:Tahoma,Geneva,Arial,sans-serif;font-size:12px;
  padding:10px 30px 10px 30px;
  margin:10px;
}

.alert-box span {
  font-weight:bold;
  text-transform:uppercase;
}

.error {
  background-color: #ffecec;
  border:1px solid #f5aca6;
}

.modal-body {
  text-align: left;
}

h1 {
  font-size: 1.9em;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

#largeLogo {
  max-width: 7.5em;
  max-height: 3em;
}

#smallLogo {
  max-width: 2em;
  max-height: 2em;
  display: none;
}

.resp-container {
  width: 100%;
  height: 100%;
}

.resp-iframe {
  position: absolute; 
  height: 90%; 
  width: 100vw;
  border: none;
}

select {
  width: 17em;
}

.neighborForm {
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 637px) {
  #largeLogo {
      display: none;
  }

  #smallLogo {
    display: block;
  }

  p, td, tr, a, select, label, input[type="text"], input[type="number"], input[type="button"], input[type="submit"] {
    font-size:.87em;
  }

  h1 {
    font-size: 1.5em;
  }

  .date, .changePdf, .deletePdf {
    display: none;
  }
}